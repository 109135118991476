// TODO: (1) Add images
// (2) Add links to the chapter section

import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import Seo from "../../components/Seo"
import historyJSON from "../../json/historie.json"
import { BookHalf } from "react-bootstrap-icons"

export const Head = () => {
  return <Seo customTitle="Historie" />
}

export default function historie() {
  const history = historyJSON

  return (
    <Layout>
      <Container className="my-5">
        <PageTitle title="Historie" />
        <p className="lh-text mb-5 lead font-weight-normal" id="oben">
          Seit 1930 engagieren sich die MotorSportFreunde Plettenberg in den
          Bereichen Kartslalom, Oldtimer und Touristik. Alljährlich findet ein
          Vorlauf zur deutschen Meisterschaft im Jugendkartslalom in Plettenberg
          statt. Zudem werden regelmäßig Veranstaltungen rund um Oldtimer und
          der dazu gehörigen Oldtimerrallye organisiert.
        </p>

        <Row>
          <Col lg={2} xl={3} aria-hidden="true">
            <BookHalf className="display-4 mb-3 mb-lg-0 text-muted" />
          </Col>
          <Col>
            <h2 className="font-weight-bold">Kapitel</h2>
            <ListGroup variant="flush" className="mb-5 mb-lg-0">
              {history.map((entry, index) => (
                <ListGroup.Item key={index} className="pl-0">
                  {index + 1}. {entry.sectionTitle}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>

        {history.map((entry, index) => (
          <Row className="py-lg-5" key={index}>
            <Col
              lg={2}
              xl={3}
              className="border-right border-lg-0"
              id={entry.year}
            >
              <p className="display-4 text-muted">{entry.year}</p>
            </Col>
            <Col>
              <p className="h1 font-weight-bold text-break mb-3 mb-lg-5">
                {entry.sectionTitle}
              </p>
              {entry.text.map((part, index) => (
                <p className="lh-text text-dark" key={index}>
                  {part}
                </p>
              ))}
            </Col>
          </Row>
        ))}
      </Container>
    </Layout>
  )
}
